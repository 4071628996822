import VonageUtil from "@/components/Vonage/vonageUtil";
import { RootState } from "@/types";
import { VonageState } from "@/types/vonage";
import { Module } from "vuex";
import { genericErrors } from "@/components/Vonage/ErrorHandler";
import log from "@/components/Vonage/DebugLogger";
import CommonUtil from "@/utils/CommonUtil";
import saveAs from "file-saver";

const VonageModule: Module<VonageState, RootState> = {
  namespaced: true,
  state(): VonageState {
    return {
      publisher: null,
      subscribers: [],
      session: null,
    };
  },
  mutations: {
    setVonageState(state: VonageState, changes: Partial<VonageState>): void {
      Object.assign(state, changes);
    },
    removeSubscriberIdx(state: VonageState, idx: number) {
      if (idx != -1) {
        state.session?.unsubscribe(state.subscribers[idx]);
        state.subscribers.splice(idx, 1);
      }
    },
    pushSubscriber(state: VonageState, subscriber: OT.Subscriber) {
      state.subscribers.push(subscriber);
    },
  },
  getters: {},
  actions: {
    setPublisher({ commit }, publisher: OT.Publisher | null): void {
      commit("setVonageState", { publisher });
    },
    setSession({ commit }, session: OT.Session | null): void {
      commit("setVonageState", { session });
    },
    clearSubscribers({ commit }): void {
      commit("setVonageState", { subscribers: [] });
    },
    addSubscriber({ commit, dispatch }, subscriber: OT.Subscriber): void {
      dispatch("removeSubscriber", subscriber.stream);
      commit("pushSubscriber", subscriber);
    },
    removeSubscriber({ state, commit }, stream: OT.Stream): void {
      if (stream) {
        const securityId = VonageUtil.getSecurityId(stream);
        if (securityId) {
          const idx = state.subscribers.findIndex((e) => securityId === VonageUtil.getSecurityId(e.stream));
          commit("removeSubscriberIdx", idx);
        }
      }
    },
    muteSelf({ state }): void {
      state.publisher?.publishAudio(false);
    },
    shutterSelf({ state }): void {
      state.publisher?.publishVideo(false);
    },
    applyUserVideoFilter({ state }, publisher: OT.Publisher | null): void {
      if (OT.hasMediaProcessorSupport()) {
        if (publisher) {
          publisher.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        } else {
          state.publisher?.applyVideoFilter({ type: "backgroundBlur", blurStrength: "high" }).catch(genericErrors);
        }
      } else {
        log(
          `User with securityId ${VonageUtil.getSecurityId(
            state.publisher?.stream
          )} has a browser that is out of date and does not support the video filter.`,
          state.publisher?.stream
        );
      }
    },
    refreshPublisherSettings({ state, rootState, dispatch }): void {
      state.publisher?.setAudioSource(rootState.selectedAudioId).catch(genericErrors);
      state.publisher?.setVideoSource(rootState.selectedVideoId).catch(genericErrors);
      const isBlurred = rootState.MediaModule?.isBlurred || false;
      if (!isBlurred) {
        state.publisher?.clearVideoFilter().catch(genericErrors);
      } else {
        dispatch("applyUserVideoFilter");
      }
    },
    async downloadRtcReport({ state }): Promise<void> {
      const desc = (stream?: OT.Stream) => {
        if (!stream) return "Data Unavailiable";
        const base = JSON.parse(stream.connection?.data || "");
        return `${base.firstName} ${base.lastname} (${stream.streamId})\n`;
      };
      const filler = () => "\n" + new Array(50).fill("-").join("") + "\n";
      let reportTxt = `Publisher Details\n`;
      reportTxt += filler();
      reportTxt += `${desc(state.publisher?.stream)}\n`;
      await state.publisher?.getRtcStatsReport().then((reportArr) => {
        reportArr.forEach((reportContainer) => {
          reportContainer.rtcStatsReport?.forEach((report) => {
            reportTxt += CommonUtil.jsonppp("", report);
            reportTxt += filler();
          });
        });
      });
      reportTxt += `\nSubscriber Details\n`;
      reportTxt += filler();
      const subPromises = state.subscribers?.map(async (subscriber) => {
        return { statsReport: await subscriber?.getRtcStatsReport(), name: desc(subscriber.stream) };
      });
      const subscriberEntries = await Promise.all(subPromises);
      subscriberEntries.forEach((subscriberReport) => {
        reportTxt += `${subscriberReport.name}\n`;
        subscriberReport.statsReport.forEach((report) => {
          reportTxt += CommonUtil.jsonppp("", report);
          reportTxt += filler();
        });
      });
      const blob = new Blob([reportTxt], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      saveAs(url, "candidate_report.txt");
      window.URL.revokeObjectURL(url);
    },
  },
};
export default VonageModule;
