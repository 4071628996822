<template lang="pug">
.presentation-container.dark-mode
  Header
  AttendanceRecord(v-if="$auth.ADMIN && learnerAttendance")
  Settings(v-if="isCheckingHardware && isConnected && streamEnabled", @close="setCheckingHardware(false)")
  .presentation.layout-grid(v-if="!isUserReady")
    WaitingRoom
  .presentation.layout-grid(v-else, :class="{ 'has-back-stage': $auth.ADMIN }")
    Session(v-if="streamEnabled")
    GridResize(v-if="isResizing && resizeEnabled")
    StaleSession(v-if="isStaleSession")
    SidebarAdmin(v-if="$auth.ADMIN && !isPresentationMode")
    BreakoutOverlay(v-if="breakoutPopup")
    MainStage
    UsersColumn(v-if="userListEnabled && !isPresentationMode")
    ChatColumn(v-if="chatEnabled && !isPresentationMode")
    Loader(v-if="showLoader")
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { ChatType, SetActiveId, SetRootState, StopBreakout } from "@/types";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Header from "@/components/Header/BaseHeader.vue";
import Settings from "@/components/Settings/Settings.vue";
import SidebarAdmin from "@/components/Sidebar/BaseSidebarAdmin.vue";
import WaitingRoom from "@/components/WaitingRoom/BaseWaitingRoom.vue";
import StaleSession from "@/components/Popup/StaleSession.vue";
import AttendanceRecord from "@/components/Popup/AttendanceRecord.vue";
import config from "@/config";
import Session from "@/components/Vonage/Session.vue";
import OT from "@opentok/client";
import Loader from "@/components/Loader/Loader.vue";
import GridResize from "@/components/GridResize/GridResize.vue";
import { LocalStorageFields } from "@/types/base";
import UsersColumn from "@/components/Sidebar/UsersColumn.vue";
import ChatColumn from "@/components/Sidebar/ChatColumn.vue";
import { GetLocalTimers } from "@/types/timer";
import MainStage from "@/components/Layout/MainStage.vue";
import BreakoutOverlay from "@/components/Breakout/BreakoutOverlay.vue";
@Component({
  components: {
    AttendanceRecord,
    BreakoutOverlay,
    ChatColumn,
    GridResize,
    Header,
    Loader,
    MainStage,
    StaleSession,
    Settings,
    Session,
    SidebarAdmin,
    UsersColumn,
    WaitingRoom,
  },
  computed: {
    ...mapState([
      "isCheckingHardware",
      "isConnected",
      "isStaleSession",
      "wasDisconnected",
      "isClassroomStarted",
      "learnerAttendance",
    ]),
    ...mapState("BreakoutsModule", ["breakoutPopup"]),
    ...mapState("LoaderModule", ["showLoader"]),
    ...mapState("TimerModule", ["completed"]),
    ...mapState("GridModule", ["isPresentationMode"]),
    ...mapGetters(["isUserReady"]),
    ...mapGetters("GridModule", ["isResizing"]),
    ...mapGetters("EventModule", ["streamEnabled", "chatEnabled", "userListEnabled", "resizeEnabled"]),
  },
  methods: {
    ...mapMutations(["setRootState", "setCheckingHardware"]),
    ...mapMutations("ChatModule", ["setActiveId"]),
    ...mapActions("ChatModule", ["groupRemoveAll"]),
    ...mapActions("TimerModule", ["getLocalTimers"]),
    ...mapActions("BreakoutsModule", ["stopBreakout"]),
  },
})
export default class VirtualClassroom extends Vue {
  private readonly isUserReady!: boolean;
  private readonly wasDisconnected!: boolean;
  private readonly isClassroomStarted!: boolean;
  private readonly getLocalTimers!: GetLocalTimers;
  private readonly setRootState!: SetRootState;
  private readonly setActiveId!: SetActiveId;
  private readonly completed!: boolean;
  private readonly stopBreakout!: StopBreakout;

  created(): void {
    this.getLocalTimers();
    OT.setLogLevel(4);
    window.history.replaceState({}, document.title, "/");
    const eventId = localStorage.getItem(LocalStorageFields.EVENT_ID);
    if (eventId) {
      this.setRootState({ trainingEventId: eventId });
      localStorage.setItem(LocalStorageFields.EVENT_ID, eventId);
    }
  }

  @Watch("isUserReady")
  UserReady(): void {
    if (this.isUserReady) this.setActiveId(ChatType.MAIN);
    else this.setActiveId(ChatType.LOBBY);
  }

  @Watch("wasDisconnected")
  sessionCompleted(): void {
    if (this.wasDisconnected) {
      if (!this.isClassroomStarted) {
        window.open(config.vaporUrl, "_self");
      }
    }
  }
  @Watch("completed")
  completedTimer() {
    if (this.completed && this.$auth.ADMIN) {
      this.stopBreakout();
    }
  }
}
</script>
